import { Select, Skeleton, useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import type { City } from '../shared/entity';
import { ComponentStyle } from '../interface';

export function MapLocation({ cityId }: { cityId: string }) {
  const toast = useToast();
  const [useCities, setUserCities] = useState<City[]>([]);

  const { data: cities = [] } = useQuery<City[]>('/api/cities', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const onChange = () => {
    // toast({ title: 'City change', description: 'Coming soon...', status: 'warning' });
  };

  useEffect(() => {
    if (cities.length && cityId) {
      const userCity = cities.find((x) => x.id == cityId);
      setUserCities([userCity]);
    }
  }, [cities, cityId]);

  return (
    <div style={styles.container}>
      <span className="material-icons" style={styles.placeIcon}>
        place
      </span>
      <Skeleton isLoaded={!!cityId && !!useCities.length}>
        <Select
          onChange={onChange}
          defaultValue={cityId}
          focusBorderColor="transparent"
          style={{ textOverflow: 'ellipsis', border: 'none', outline: 'none' }}
        >
          {useCities.map((city) => (
            <option value={city.id} key={city.id}>
              {city?.name}, {city?.state}
            </option>
          ))}
        </Select>
      </Skeleton>
    </div>
  );
}

const styles: ComponentStyle = {
  container: {
    padding: '0.4rem 1.0rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRight: '1px solid var(--separator-color)',
  },
  placeIcon: {
    color: 'var(--border-color-lighter)',
  },
};
