import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider, Skeleton } from '@chakra-ui/react';

import 'zoomist/css';
import './style.css';
import { theme } from './shared/theme.js';
import { MainLayout } from './shared/component/MainLayout.js';
import { state } from './shared/state.js';
import { Map } from './map/Map.js';
import ErrorBoundary from './errorhandler/ErrorBoundary.js';
import './errorhandler/sentry.js';
import GlobalProvider from './context/GlobalContext.js';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn(context) {
        return fetch(context.queryKey[0] as string, { headers: { 'content-type': 'application/json' } })
          .then((res) => res.json())
          .then((res) => res.data);
      },
    },
  },
});

const ensureLogIn = () => {
  if (state.user) {
    return null;
  }
  return redirect('/login');
};

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <MainLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: 'map',
        index: true,
        element: (
          <ErrorBoundary>
            <Map />
          </ErrorBoundary>
        ),
        loader: ensureLogIn,
      },
      {
        path: 'plan',
        index: true,
        async lazy() {
          const { Plan } = await import(/* webpackPrefetch: true */ './plan/Plan.js');
          return { Component: Plan };
        },
        loader: ensureLogIn,
      },
      {
        path: 'metrics',
        index: true,
        async lazy() {
          const { Metrics } = await import(/* webpackPrefetch: true */ './metric/Metrics.js');
          return { Component: Metrics };
        },
        loader: ensureLogIn,
      },
      {
        path: 'settings',
        index: true,
        async lazy() {
          const { Settings } = await import(/* webpackPrefetch: true */ './setting/Settings.js');
          return { Component: Settings };
        },
        loader: ensureLogIn,
      },
      {
        path: 'account',
        index: true,
        async lazy() {
          const { Account } = await import(/* webpackPrefetch: true */ './user/Account.jsx');
          return { Component: Account };
        },
        loader: ensureLogIn,
      },
      {
        path: 'login',
        index: true,
        async lazy() {
          const { LoginForm } = await import(/* webpackPrefetch: true */ './user/LoginForm.js');
          return { Component: LoginForm };
        },
      },
      {
        path: '*',
        async loader() {
          return redirect('/map');
        },
        shouldRevalidate({ nextUrl }) {
          return nextUrl.pathname === '/';
        },
      },
    ],
  },
]);

const root = createRoot(document.querySelector('#container'));

root.render(
  <GlobalProvider>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} fallbackElement={<Skeleton height="1rem" />} />
      </ChakraProvider>
    </QueryClientProvider>
  </GlobalProvider>
);
