import { Fragment, useMemo, useRef } from 'react';
import { Spinner } from '@chakra-ui/react';
import type { LmmImage } from '../shared/entity.js';
import { PciCategory, pciCategories } from '../shared/const.js';
import { percent, round } from '../../server/shared/number.helper.js';
import { ProgressBarStyled } from '../shared/component/ProgressBarStyled.js';
import { ComponentStyle } from '../interface.js';
import { PCI_COLORS } from '../utils/constants.js';
import { capitalizeEachWord } from '../utils/stringUtils.js';
import { getColorFromCategoryKey, getColorFromPci } from './mapHelper.js';

const width = 25;

export function SegmentPci({ images }: { images: LmmImage[] }) {
  const average = useRef<number>(0);
  const categoryPCIPercentagescores = useMemo(() => {
    let excellent = 0;
    let good = 0;
    let fair = 0;
    let poor = 0;
    const imagesLength = images?.length;
    if (images) {
      let totalPci = 0;
      for (const image of images) {
        if (!image.pci) continue;
        totalPci += image.pci;
        const pciColor = getColorFromPci(image.pci);
        if (pciColor == PCI_COLORS.excellent) excellent++;
        else if (pciColor == PCI_COLORS.good) good++;
        else if (pciColor == PCI_COLORS.fair) fair++;
        else poor++;
      }
      average.current = round(totalPci / imagesLength);
    }
    return {
      excellent: percent(excellent, imagesLength),
      good: percent(good, imagesLength),
      fair: percent(fair, imagesLength),
      poor: percent(poor, imagesLength),
    };
  }, [images]);

  const styles = getStyles(width);
  return (
    <Fragment>
      <div style={styles.container}>
        <div style={styles.box}>
          {average.current ? (
            <div style={styles.pciInner}>
              <div style={styles.pciBox}>
                <div style={styles.pciTitle}>PCI</div>
                <div style={styles.pciValue}>{average.current}</div>
              </div>

              {Object.keys(pciCategories).map((key) =>
                categoryPCIPercentagescores[key as unknown as PciCategory] == 0 ? (
                  <Fragment key={key} />
                ) : (
                  <SegmentPciScore
                    category={key as PciCategory}
                    categoryPCIPercentagescores={categoryPCIPercentagescores}
                    key={key}
                    percent={categoryPCIPercentagescores[key as unknown as PciCategory]}
                  />
                )
              )}
            </div>
          ) : (
            <Spinner
              width="25px"
              height="25px"
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="gray.500"
              size="xl"
            />
          )}
        </div>
      </div>
      <div style={styles.divider}></div>
    </Fragment>
  );
}

function SegmentPciScore({
  categoryPCIPercentagescores,
  category,
  percent,
}: {
  categoryPCIPercentagescores: { excellent: number; good: number; fair: number; poor: number };
  category: PciCategory;
  percent: number;
}) {
  const color = getColorFromCategoryKey(category);
  const width = round((categoryPCIPercentagescores[category] / 100) * 22);
  const styles = getStyles(width);
  return (
    <div style={styles.pciScoreContainer}>
      <div style={styles.pciScorePercent} title={`${percent}%`}>
        {percent}%
      </div>
      <ProgressBarStyled color={color} max={400} value={400} />
      <div style={styles.pciScoreCategory} title={capitalizeEachWord(category)}>
        {category}
      </div>
    </div>
  );
}

function getStyles(width: number) {
  return {
    container: {
      width: width + 'rem',
      paddingLeft: '28px',
      paddingRight: '28px',
      fontSize: '0.8rem',
      margin: 'auto 0',
    },
    box: {
      display: 'flex',
      gap: '0.3rem',
      flexFlow: 'column',
    },
    divider: {
      width: '1px',
      height: '100%',
      background: 'var(--separator-color)',
    },
    pciTitle: {
      textTransform: 'uppercase',
      color: 'var(--color--secondary)',
    },
    pciInner: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.3rem',
    },
    pciBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '18px',
      marginTop: '2px',
    },
    pciValue: {
      fontSize: '20px',
      fontWeight: '600',
    },
    pciScoreContainer: {
      position: 'relative',
      height: '100%',
      width: width + 'rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.1rem',
    },
    pciScorePercent: {
      color: 'var(--color)',
      fontSize: '0.9rem',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
    },
    pciScoreCategory: {
      fontSize: '0.75rem',
      color: 'var(--color--secondary)',
      textTransform: 'capitalize',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  } satisfies ComponentStyle;
}
