import { useMemo } from 'react';
import { adjustCentermile } from '../../server/shared/number.helper.js';
import { LmmSegment } from '../shared/entity.js';
import * as s from './SegmentCenterline.module.css';

export function SegmentCenterline({ segments, cityId }: { segments: LmmSegment[]; cityId: string }) {
  const segmentLane = useMemo(() => {
    let centerline = 0;
    let lane = 0;
    for (const segment of segments) {
      centerline += segment.centerline;
      lane += segment.lane;
    }
    return {
      centerline: centerline || 0,
      lane: lane || 0,
    };
  }, [segments]);
  return (
    <div className={s.container}>
      <div className={s.centerlineBox}>
        <div className={s.centerlineText}>Centerline Miles</div>
        <div className={s.value}>{adjustCentermile(segmentLane.centerline)}</div>
      </div>

      {cityId != '0a7e2db8-4e43-4f7e-afd0-d18b186178d7' && (
        <>
          <div className={s.divider}></div>
          <div className={s.laneBox}>
            <div className={s.laneText}>Lane Miles</div>
            <div className={s.value}>{adjustCentermile(segmentLane.lane)}</div>
          </div>
        </>
      )}
    </div>
  );
}
