import { useContext } from 'react';
import { MapLocation } from '../../map/MapLocation.js';
import { MapMenu } from '../../map/MapMenu.js';
import { MapSearch } from '../../map/MapSearch.js';
import { SegmentCenterline } from '../../map/SegmentCenterline.js';
import { SegmentPci } from '../../map/SegmentPci.js';
import { SettingsMenu } from '../../map/SettingsMenu.js';
import { GlobalContext } from '../../context/GlobalContext.js';
import { GetTenantMap } from '../../query/mapqueries.js';
import { useMapRecenter } from '../hook/useMapRecenter.js';
import { UseRecenterParams } from '../hook/interface.js';
import { LmmSegment } from '../entity';
import * as s from './styles/TopNavBar.module.css';

interface Params extends UseRecenterParams {
  segments?: LmmSegment[] | null;
}

export default function TopNavBar({ mapRef, markersRef, segments }: Params) {
  const { state } = useContext(GlobalContext);
  const recenter = useMapRecenter({ mapRef, markersRef });
  const { data: map = {} } = GetTenantMap();
  return (
    <header className={s.mapTopBar}>
      {/* <MapMenu /> */}
      <MapLocation cityId={map.cityId} />
      <SegmentPci images={state.imagesInView} />
      <SegmentCenterline cityId={map.cityId} segments={segments || [map]} />
      <MapSearch recenter={recenter} images={state.lmmImages} />
      <SettingsMenu />
    </header>
  );
}
