import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { stringify } from 'qs';
import type { Geometry } from 'geojson';
import { useToast } from '@chakra-ui/react';
import type { LmmImage, LmmImagesFilter, LmmRoad, LmmRoadAsset, LmmSection, LmmSegment } from '../shared/entity.js';
import { useTitle } from '../shared/hook/useTitle.js';
import { AssetType, defaultPciRange } from '../shared/const.js';
import { capitalizeEachWord } from '../utils/stringUtils.js';
import { ShowRoadAssetsFilter } from '../interface.js';
import { GetLmmRoads, GetTenantMap } from '../query/mapqueries.js';
import { useMapRecenter } from '../shared/hook/useMapRecenter.js';
import TopNavBar from '../shared/component/TopNavBar.js';
import { GlobalContext } from '../context/GlobalContext.js';
import DISPATCH_ACTIONS from '../context/actions.js';
import {
  addFeatureGroup,
  buildFeatures,
  buildSection,
  convertToGoogleMapsGeometry,
  getColorFromPci,
  getSegmentMarkers,
  isSegmentImage,
  isSegmentMarker,
  removeFeatureGroup,
} from './mapHelper.js';
import { buildLmmImageMarker, buildStopSignMarker } from './mapMarkers.js';
import { MapLmmImageModal } from './MapLmmImageModal.js';
import { MapToolbar } from './MapToolbar.js';
import { MapRoadAssetModal } from './MapRoadAssetModal.js';

export function Map() {
  const { state, dispatch } = useContext(GlobalContext);
  const mapElRef = useRef<HTMLDivElement>();
  const mapRef = useRef<google.maps.Map>();
  const [selectedLmmImageId, setSelectedLmmImageId] = useState<string>();
  const roadSignsRef = useRef<LmmRoadAsset[]>();
  const guardrailsRef = useRef<LmmRoadAsset[]>();
  const previouslySelectedLmmImageRef = useRef<LmmImage>();
  const [selectedLmmSection, setSelectedLmmSection] = useState<LmmSection>();
  const [selectedRoadAsset, setSelectedRoadAsset] = useState<LmmRoadAsset>();
  const sectionsRef = useRef<LmmSection[]>();
  // const roadsRef = useRef<LmmRoad[]>();
  const [selectedRoad, setSelectedRoad] = useState<LmmRoad>();
  const preloadedSegmentImages = useRef<{ [segmentId: string]: true }>({});
  const prevSelectedLmmSection = useRef<LmmSection>();
  const [lmmImagesFilter, setLmmImagesFilter] = useState<LmmImagesFilter>({ pci: [0, 100] });
  const lmmImagesFilterStr = useMemo(() => stringify(lmmImagesFilter), [lmmImagesFilter]);
  const markersRef = useRef<readonly google.maps.marker.AdvancedMarkerElement[]>([]);
  const roadAssetMakersRef = useRef<readonly google.maps.marker.AdvancedMarkerElement[]>([]);
  const hasAutoFitBoundsRanRef = useRef<boolean>();
  const [selectedSegment, setSelectedSegment] = useState<LmmSegment>();
  const [sectionIsSelected, setSectionIsSelected] = useState<boolean>();
  const [isLoadingSections, setIsLoadingSections] = useState<boolean>();
  const featuresRemovedRef = useRef<google.maps.Data.Feature[]>([]);
  const forceUseImageCoordinateForCentering = useRef<boolean>(false);
  const [assetFilterisActive, setAssetsFilterisActive] = useState<boolean>();
  const toast = useToast();
  const [mapIsSet, setMapIsSet] = useState<boolean>(false);
  const { data: map = {} } = GetTenantMap();
  const { data: roadsRef } = GetLmmRoads();
  const [displayedFeatures, setDisplayedFeatures] = useState({});
  const [endAnction, setEndAction] = useState('');
  const [showAssets, setShowAssets] = useState({ roadSigns: true, guardrails: true });
  const [showFilter, setShowFilter] = useState<ShowRoadAssetsFilter>({
    roadSign: true,
    guardrail: true,
    current: 'all',
  });

  const onToggleShowRoadAsset = (type: AssetType) => {
    const copyFilter = { ...showFilter };
    if (type) {
      copyFilter[type] = !copyFilter[type];
      copyFilter.current = type;
    } else {
      copyFilter.guardrail = true;
      copyFilter.roadSign = true;
      copyFilter.current = 'all';
      setAssetsFilterisActive(false);
      // setEndAction('reset');
    }
    setShowFilter(copyFilter);
  };

  const segments = useMemo(() => {
    if (selectedSegment) {
      const sections = sectionsRef.current.filter((x) => x.roadId == selectedLmmSection?.roadId);
      return sections;
    } else return [map];
  }, [map, selectedLmmSection, selectedSegment]);

  const selectedLmmImage = useMemo<LmmImage>(
    () => (selectedLmmImageId ? state.lmmImages.find((lmmImage) => lmmImage.id === selectedLmmImageId) : undefined),
    [selectedLmmImageId, state.lmmImages]
  );

  useTitle(map?.name);

  const recenter = useMapRecenter({ mapRef, markersRef });

  const autoCenter = useCallback(
    (
      reference:
        | Geometry
        | google.maps.LatLng
        | google.maps.Data.Geometry
        | google.maps.Data
        | readonly google.maps.marker.AdvancedMarkerElement[]
    ) => {
      if (hasAutoFitBoundsRanRef.current) {
        return;
      }
      hasAutoFitBoundsRanRef.current = true;
      recenter(reference);
    },
    [recenter]
  );

  const revertMarkerView = (clearPreviousMarkerState?: boolean) => {
    markersRef.current.forEach((marker) => {
      if (previouslySelectedLmmImageRef.current && marker.id === previouslySelectedLmmImageRef.current.id) {
        marker.content = buildLmmImageMarker(previouslySelectedLmmImageRef.current);
        marker.map = mapRef.current;
        if (clearPreviousMarkerState) {
          previouslySelectedLmmImageRef.current = undefined;
          forceUseImageCoordinateForCentering.current = false;
        }
      }
    });
  };

  useEffect(() => {
    const initializeMap = async () => {
      const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;

      mapRef.current = new Map(mapElRef.current, {
        mapId: 'map',
        mapTypeControlOptions: {
          position: google.maps.ControlPosition?.BOTTOM_LEFT,
        },
        noClear: true,
        fullscreenControl: false,
      });
      setMapIsSet(true);
    };
    try {
      initializeMap();
    } catch (error) {
      initializeMap();
    }
  }, []);

  useEffect(() => {
    if (mapIsSet) {
      mapRef.current.data.addListener('click', ({ feature }: { feature: google.maps.Data.Feature }) => {
        // onFilterMap(defaultPciRange);
        setEndAction('reset');
        setTimeout(() => {
          // setEndAction('reset');
          const section = buildSection(feature);
          markersRef.current.forEach((marker) => {
            if (isSegmentMarker(marker, section)) {
              marker.map = mapRef.current;
            }
          });
          const allRelatedSections = sectionsRef.current.filter((x) => x.roadId == section.roadId);
          const featuresToRemove: google.maps.Data.Feature[] = [];
          for (const section of allRelatedSections) {
            const feature = mapRef.current.data.getFeatureById(section.id);
            featuresToRemove.push(feature);
            mapRef.current.data.remove(feature);
          }

          if (featuresRemovedRef.current?.length) {
            const sectionInRemoval = buildSection(featuresRemovedRef.current[0]);
            if (sectionInRemoval.roadId !== section.roadId) {
              for (const feature of featuresRemovedRef.current) {
                mapRef.current.data.add(feature);
              }
            }
          }

          featuresRemovedRef.current = featuresToRemove;
          setSelectedLmmSection(section);
        }, 300);
      });
    }
  }, [mapIsSet]);

  useEffect(() => {
    const checkFilterToShow = async () => {
      const runForRoadSign = roadSignsRef.current && (showFilter.current == 'all' || showFilter.current == 'roadSign');
      const runForGuardrail =
        guardrailsRef.current && (showFilter.current == 'all' || showFilter.current == 'guardrail');
      if (showFilter.roadSign && runForRoadSign) {
        await showRoadSigns();
      } else if (runForRoadSign) {
        await hideRoadSigns();
      }
      if (showFilter.guardrail && runForGuardrail) {
        const guardRailsCords = guardrailsRef.current.map((x) => x.geometry);
        addFeatureGroup(mapRef.current, guardRailsCords, AssetType.guardrail, '#F34D2A', setDisplayedFeatures);
      } else if (runForGuardrail) {
        removeFeatureGroup(AssetType.guardrail, setDisplayedFeatures);
      }
    };
    if (mapIsSet) {
      checkFilterToShow();
    }
  }, [mapIsSet, showFilter]);

  const updateImagesInView = useCallback(
    (roadId?: string) => {
      if (!roadId) {
        dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: state.lmmImages });
      } else {
        const newImagesInView = state.lmmImages.filter((x) => x.roadId == roadId);
        dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: newImagesInView });
      }
    },
    [dispatch, state.lmmImages]
  );

  useEffect(() => {
    if (selectedLmmImage && mapIsSet) {
      forceUseImageCoordinateForCentering.current = true;
      if (selectedLmmImage.roadId !== prevSelectedLmmSection.current?.roadId) {
        const road = roadsRef.find((x) => x.id == selectedLmmImage.roadId);
        setSelectedRoad(road);
        toast({
          title: road?.name ? `You are now on ${capitalizeEachWord(road.name)}` : 'Switching to new road',
          status: 'info',
          duration: 3000,
        });
        updateImagesInView(road.id);
        const nextSection = sectionsRef.current.find((x) => x.roadId == selectedLmmImage.roadId);
        if (!nextSection.geometry?.type) {
          nextSection.geometry = convertToGoogleMapsGeometry(nextSection.geometry) as unknown as Geometry;
        }
        prevSelectedLmmSection.current = selectedLmmSection;
        setSelectedLmmSection(nextSection);
        setSelectedSegment(nextSection);
        markersRef.current.forEach((marker) => {
          if (marker.dataset.roadId === nextSection.roadId) {
            marker.map = mapRef.current;
            if (marker.id === selectedLmmImage.id) {
              marker.content = buildLmmImageMarker(selectedLmmImage, selectedLmmImageId);
              marker.map = mapRef.current;
            }
          }
        });
        const allRelatedSections = sectionsRef.current.filter((x) => x.roadId == nextSection.roadId);
        const featuresToRemove: google.maps.Data.Feature[] = [];
        for (const section of allRelatedSections) {
          const feature = mapRef.current.data.getFeatureById(section.id);
          featuresToRemove.push(feature);
          mapRef.current.data.remove(feature);
        }
        for (const feature of featuresRemovedRef.current) {
          mapRef.current.data.add(feature);
        }
        featuresRemovedRef.current = featuresToRemove;
      } else {
        markersRef.current.forEach((marker) => {
          if (marker.id === selectedLmmImage.id) {
            marker.content = buildLmmImageMarker(selectedLmmImage, selectedLmmImageId);
            marker.map = mapRef.current;
          }
        });
      }
      recenter(
        new google.maps.LatLng(
          selectedLmmImage.position.coordinates[0],
          selectedLmmImage.position.coordinates[1] + 0.0007 //move to left
        ),
        19
      );
    }
    if (previouslySelectedLmmImageRef.current) {
      revertMarkerView();
    }

    previouslySelectedLmmImageRef.current = selectedLmmImage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLmmImage, mapIsSet, selectedLmmImageId, recenter, toast, updateImagesInView]);

  useEffect(() => {
    if (
      !selectedLmmSection ||
      preloadedSegmentImages.current[selectedLmmSection.roadId] ||
      preloadedSegmentImages.current[selectedLmmSection.id]
    ) {
      return;
    }
    for (const lmmImage of state.lmmImages) {
      if (!isSegmentImage(lmmImage, selectedLmmSection)) {
        continue;
      }
      const img = new Image();
      img.src = lmmImage.path;
      img.onload = function () {
        // Warning! this function + log seems to be required (image has to be referenced) - please don't touch.
        console.log('** image preloaded', this);
      };
    }
    updateImagesInView(selectedLmmSection.roadId);
  }, [selectedLmmSection, state.lmmImages, updateImagesInView]);

  useEffect(() => {
    if (mapIsSet) {
      mapRef.current.data.setStyle((feature) => {
        const id = feature.getId();
        const section = buildSection(feature);
        const strokeColor = getColorFromPci(section.average);
        const strokeOpacity = !selectedLmmSection || selectedLmmSection.id === id ? 1 : 0.3;
        return {
          strokeColor,
          strokeOpacity,
          strokeWeight: 8,
        };
      });

      if (prevSelectedLmmSection.current) {
        markersRef.current.forEach((marker) => {
          if (isSegmentMarker(marker, prevSelectedLmmSection.current)) {
            marker.map = undefined;
          }
        });
      }
      if (selectedLmmSection && !forceUseImageCoordinateForCentering.current) {
        const markers = getSegmentMarkers(markersRef.current, selectedLmmSection);
        recenter(markers, 19);
      }

      prevSelectedLmmSection.current = selectedLmmSection;

      setSelectedSegment(selectedLmmSection);
      setSectionIsSelected(!!selectedLmmSection);
    }
  }, [mapIsSet, recenter, selectedLmmSection]);

  const { refetch: refetchImages } = useQuery<LmmImage[]>(`/api/images?${lmmImagesFilterStr}`, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(newLmmImages) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMIMAGES, payload: newLmmImages });
      if (!selectedLmmSection) {
        dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: newLmmImages });
      }
      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
      const allMarkers = newLmmImages.map((img) => {
        const marker = new AdvancedMarkerElement({
          position: {
            lat: img.position.coordinates[0],
            lng: img.position.coordinates[1],
          },
          content: buildLmmImageMarker(img, selectedLmmImageId),
        });

        marker.id = img.id;
        marker.dataset.roadId = img.roadId;
        marker.dataset.sectionId = img.sectionId;
        marker.dataset.pci = `${img.pci}`;
        marker.addListener('click', () => {
          setSelectedLmmImageId(img.id);
          const road = roadsRef.find((x) => x.id == img.roadId);
          setSelectedRoad(road);
        });

        return marker;
      });

      autoCenter(allMarkers);
      if (!selectedLmmImageId) {
        markersRef.current = allMarkers;
      }
    },
  });

  useEffect(() => {
    if (showFilter.guardrail && showFilter.roadSign) {
      setAssetsFilterisActive(false);
    } else {
      setAssetsFilterisActive(true);
    }
  }, [showFilter]);

  const { refetch: refetchRoadSigns } = useQuery<LmmRoadAsset[]>('/api/roadassets', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(newLmmRoadAssets) {
      const guardRails = newLmmRoadAssets.filter((x) => x.assetType == AssetType.guardrail);
      const showAssetCopy = { ...showAssets };
      showAssetCopy.guardrails = guardRails.length > 0;
      const guardRailsCords = guardRails.map((x) => x.geometry);
      const roadSigns = newLmmRoadAssets.filter((x) => x.assetType == AssetType.roadSign);
      showAssetCopy.roadSigns = roadSigns.length > 0;
      setShowAssets(showAssetCopy);
      guardrailsRef.current = guardRails;
      roadSignsRef.current = roadSigns;
      addFeatureGroup(mapRef.current, guardRailsCords, AssetType.guardrail, '#F34D2A', setDisplayedFeatures);

      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
      const allMarkers = roadSigns.map((asset) => {
        const marker = new AdvancedMarkerElement({
          position: {
            lat: asset.geometry.coordinates[0],
            lng: asset.geometry.coordinates[1],
          },
          map: mapRef.current,
          content: buildStopSignMarker(),
        });
        // marker.addListener('click', () => {
        //   // setSelectedLmmImageId(img.id);
        //   // const road = roadsRef.find((x) => x.id == img.roadId);
        //   // setSelectedRoad(road);
        //   setSelectedRoadAsset(asset);
        // });
        marker.id = asset.id;
        marker.dataset.type = 'roadSign';
        return marker;
      });

      roadAssetMakersRef.current = allMarkers;
    },
  });

  const showRoadSigns = async () => {
    roadAssetMakersRef.current.forEach((marker, c) => {
      if (marker.dataset.type == 'roadSign') {
        marker.map = mapRef.current;
      }
    });
  };

  const hideRoadSigns = async () => {
    roadAssetMakersRef.current.forEach((marker, c) => {
      if (marker.dataset.type == 'roadSign') {
        marker.map = undefined;
      }
    });
  };

  const { refetch: refetchLmmSections } = useQuery<LmmSection[]>(`/api/sections?${lmmImagesFilterStr}`, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess(sections) {
      sectionsRef.current = sections;
      dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: state.lmmImages });
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMSECTIONS, payload: sections });
      clear();
      const features = buildFeatures(sections);
      mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });
      autoCenter(mapRef.current.data);
    },
  });

  const onToggleToSectionView = () => {
    clear();
    const features = buildFeatures(sectionsRef.current);
    mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });
    updateImagesInView();
    if (lmmImagesFilter.pci != defaultPciRange) {
      setLmmImagesFilter({ pci: defaultPciRange });
      setAssetsFilterisActive(false);
    }
  };

  const loopLmmImage = (direction: 1 | -1) => {
    const index = state.lmmImages.findIndex(({ id }) => id === selectedLmmImage.id);
    const newIndex = (index + direction + state.lmmImages.length) % state.lmmImages.length;
    setSelectedLmmImageId(state.lmmImages[newIndex].id);
  };

  const closeLmmImageModal = () => {
    revertMarkerView(true);
    setSelectedLmmImageId(undefined);
  };

  const closeLmmRoadAssetModal = () => {
    setSelectedRoadAsset(undefined);
  };

  const clearLmmImages = () => {
    markersRef.current.forEach((marker) => {
      if (marker.dataset.type !== 'roadSign') {
        marker.map = undefined;
      }
    });
  };

  const clearLmmSections = () => {
    mapRef.current.data.forEach((feature) => mapRef.current.data.remove(feature));
    setSelectedLmmSection(undefined);
  };

  const clear = () => {
    clearLmmImages();
    clearLmmSections();
    setSectionIsSelected(false);
  };

  const onSaveImage = (image: LmmImage) => {
    revertMarkerView(true);
    refetchImages();
  };

  const onFilterMap = (pci: [number, number]) => {
    if (selectedLmmSection) {
      console.log('selectedLmmSection');
      // clear();
      if (pci[0] != 0 || pci[1] != 100) {
        console.log('pci', pci);
      }
      //TODO:
      // const features = buildFeatures(state.lmmSections);
      // mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });

      const imageInView: LmmImage[] = [];
      markersRef.current.forEach((marker) => {
        if (marker.dataset.roadId != selectedLmmSection.roadId) {
          return;
        }
        const imagePci = Number(marker.dataset.pci);
        if ((imagePci >= pci[0] && imagePci <= pci[1]) || (Number.isNaN(imagePci) && pci[0] == 0 && pci[1] == 100)) {
          const image = { id: marker.id, pci: imagePci };
          marker.content = buildLmmImageMarker(image);
          marker.map = mapRef.current;
          if (!Number.isNaN(imagePci)) {
            imageInView.push(image);
          }
        } else {
          marker.map = undefined;
        }
      });
      dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: imageInView });
    } else {
      // TODO:
      console.log('NO selectedLmmSection');
      // clear()
      // setLmmImagesFilter({ ...lmmImagesFilter, pci });
      const filterdSections = state.lmmSections.filter((x) => x.average >= pci[0] && x.average <= pci[1]);
      // dispatch({ type: DISPATCH_ACTIONS.SET_IMAGESINVIEW, payload: state.lmmImages });
      clear();
      const features = buildFeatures(filterdSections);
      mapRef.current.data.addGeoJson({ type: 'FeatureCollection', features });
      // autoCenter(mapRef.current.data);
      // const lmmImages = [...state.lmmImages];
    }
  };

  const adjustIconSize = () => {
    const zoomLevel = window.devicePixelRatio;
    const elements = document.querySelectorAll('.signMarker');
    elements.forEach((element) => {
      element.style.transform = `scale(${zoomLevel})`; // Example transformation
    });
    // icon. = `scale(${zoomLevel})`;
  };
  window.addEventListener('resize', adjustIconSize);

  adjustIconSize();

  return (
    <>
      <TopNavBar mapRef={mapRef} markersRef={markersRef} segments={segments} />
      <div ref={mapElRef} style={mapStyle} className="map">
        <MapToolbar
          lmmImagesFilter={lmmImagesFilter}
          setLmmImagesFilter={setLmmImagesFilter}
          onFilterMap={onFilterMap}
          recenter={recenter}
          deselectLmmSection={onToggleToSectionView}
          showDeselectSection={sectionIsSelected}
          loadingSection={isLoadingSections}
          toggleRoadAssetView={onToggleShowRoadAsset}
          assetFilterisActive={assetFilterisActive}
          showFilter={showFilter}
          action={endAnction}
          setAction={setEndAction}
          showGuardrails={showAssets.guardrails}
          showRoadSigns={showAssets.roadSigns}
        />
        {selectedLmmImage && (
          <MapLmmImageModal
            lmmImage={selectedLmmImage}
            loop={loopLmmImage}
            onSave={onSaveImage}
            onClose={closeLmmImageModal}
            roadName={selectedRoad?.name}
          />
        )}
        {selectedRoadAsset && <MapRoadAssetModal lmmRoadAsset={selectedRoadAsset} onClose={closeLmmRoadAssetModal} />}
      </div>
    </>
  );
}

const mapStyle = {
  width: '100vw',
  height: '100vh',
} satisfies CSSProperties;
