import { useQuery } from 'react-query';
import { useContext } from 'react';
import { LmmMap, LmmRoad } from '../shared/entity';
import { GlobalContext } from '../context/GlobalContext.js';
import DISPATCH_ACTIONS from '../context/actions.js';

export const GetTenantMap = () => {
  return useQuery<LmmMap>('/api/maps/one', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
  });
};

export const GetLmmRoads = () => {
  const { dispatch } = useContext(GlobalContext);
  return useQuery<LmmRoad[]>('/api/roads', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    onSuccess(roads) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMROADS, payload: roads });
    },
  });
};
