import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Input,
  IconButton,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { LmmImage, LmmRoadAsset } from '../shared/entity.js';
import { MyIcon } from '../shared/component/Icons.js';
import { formatDate } from '../../server/shared/date.helper.js';
import { Zoom } from '../shared/component/Zoom.js';
import { capitalizeEachWord } from '../utils/stringUtils.js';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { getCategoryKeyFromPci, getColorFromCategoryKey } from './mapHelper.js';
import * as s from './MapLmmImageModal.module.css';

export function MapRoadAssetModal({
  lmmRoadAsset,
  // loop,
  // onSave,
  onClose,
}: {
  lmmRoadAsset: LmmRoadAsset;
  // loop: (direction: 1 | -1) => void;
  // onSave(lmmImage: LmmImage): void;
  onClose(): void;
}) {
  const toast = useToast();
  // const [isEditing, setIsEditing] = useState<boolean>();
  // const [isSubmiting, setIsSubmiting] = useState<boolean>();
  // const [editValue, setEditValue] = useState<any>(lmmImage?.pci);
  // const categoryKey = getCategoryKeyFromPci(lmmImage.pci);
  // const color = getColorFromCategoryKey(categoryKey);
  // const { register, handleSubmit, setFocus, reset } = useForm<LmmImage, LmmImage, LmmImage>();
  // const [isPciInputChanged, setIsPciInputChanged] = useState<boolean>();

  // const submit = handleSubmit((body) => {
  //   if (!isPciInputChanged || lmmImage.pci == body.pci) {
  //     toast({
  //       title: 'Change PCI value to update it.',
  //       status: 'error',
  //       duration: 3000,
  //     });
  //     return;
  //   }
  //   setIsSubmiting(true);
  //   fetch(`/api/images/${lmmImage.id}`, {
  //     body: JSON.stringify(body),
  //     method: 'put',
  //     headers: { 'content-type': 'application/json' },
  //   })
  //     .then(() => {
  //       onSave({ ...lmmImage, ...body });
  //       exitEditMode();
  //       setIsSubmiting(false);
  //       toast({
  //         title: 'PCI saved',
  //         status: 'success',
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       reset();
  //       setIsSubmiting(false);
  //       toast({
  //         title: 'Ups, something unexpected ocurred.',
  //         status: 'error',
  //       });
  //     });
  // });

  // const cancel = () => {
  //   exitEditMode();
  //   reset();
  // };

  // const onKeyUp = (evt: any) => {
  //   if (evt.key === 'Escape') {
  //     cancel();
  //   } else {
  //     setIsPciInputChanged(true);
  //   }
  // };

  // const enterEditMode = () => {
  //   setEditValue(null);
  //   setIsEditing(true);
  //   setTimeout(() => setFocus('pci'), 0);
  //   setTimeout(() => {
  //     setEditValue(lmmImage.pci);
  //   }, 500);
  // };

  // useEffect(() => {
  //   setEditValue(lmmImage.pci);
  // }, [lmmImage]);

  // const exitEditMode = () => {
  //   setIsEditing(false);
  //   setIsPciInputChanged(false);
  //   setEditValue(null);
  // };

  const { height } = useScreenDimensions();

  return (
    <Modal blockScrollOnMount={false} isOpen onClose={onClose} returnFocusOnClose={false} closeOnEsc={false}>
      <ModalOverlay sx={{ background: 'rgba(0, 0, 0, 0.074)' }} />
      <ModalContent
        bg="var(--bg-color)"
        sx={{ width: '45%', maxWidth: '45%', height: `${height - 130}px`, top: '46px' }}
      >
        <ModalBody className={s.body}>
          <div className={s.header}>
            <div className={s.roadname}>ROAD ASSET</div>
            {/* <div className={s.date}>{formatDate(lmmImage.createdAt)}</div> */}
            <div className={s.date}>May, 2024</div>
            <ModalCloseButton className={s.close} />
          </div>

          <div style={{ position: 'relative', height: '87%' }}>
            <Zoom
              src="https://thumbs.dreamstime.com/b/road-sign-form-white-rectangle-red-circle-no-e-entry-119229814.jpg"
              alt={80 + ''}
              options={{
                maxScale: 4,
                slider: true,
                zoomer: true,
              }}
            />
            {/* <button onClick={() => loop(-1)} className={s.prev}>
              ❮
            </button>
            <button onClick={() => loop(1)} className={s.next}>
              ❯
            </button> */}
          </div>

          <form>
            <div className={s.pciWrapper}>
              <div>Stop Sign&nbsp;</div>
              <div style={{ display: 'flex', alignItems: 'center', width: '2rem' }}>
                <span style={{ color: '#C0C0C0', textTransform: 'capitalize' }} role="button" tabIndex={-1}></span>
              </div>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
