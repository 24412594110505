import { css } from '@emotion/css';
import { ComponentStyle } from '../../interface';

// open https://fonts.google.com/icons, copy & paste the icon name
export type MyIconKey =
  | 'arrow_back'
  | 'alternate_email'
  | 'deselect'
  | 'visibility'
  | 'visibility_off'
  | 'logout'
  | 'login'
  | 'close_small'
  | 'close'
  | 'check'
  | 'recenter'
  | 'search'
  | 'filter_alt'
  | 'account_circle'
  | 'settings'
  | 'menu'
  | 'query_stats'
  | 'checklist'
  | 'event_list'
  | 'map'
  | 'expand_more'
  | 'more_vert'
  | 'send'
  | 'mic'
  | 'place'
  | 'delete'
  | 'pause_circle'
  | 'play_circle'
  | 'add'
  | 'link'
  | 'upload_file'
  | 'youtube_activity'
  | 'keyboard_arrow_down'
  | 'keyboard_arrow_up'
  | 'arrow_upward_alt'
  | 'arrow_downward_alt'
  | 'keyboard_arrow_right'
  | 'apartment'
  | 'lock'
  | 'radio_button_unchecked';

export const MyIcon = ({ icon, style = {} }: { icon: MyIconKey; style?: any }) => (
  <span className="material-symbols-outlined" style={{ ...style }}>
    {icon}
  </span>
);

export const MyIconFilled = ({ icon, style = {} }: { icon: MyIconKey; style?: any }) => (
  <span className="material-icons" style={{ ...style }}>
    {icon}
  </span>
);

export const arrowIconStyle = (color = 'var(--color)') => css`
  .material-symbols-outlined {
    color: transparent;
    transform: translateX(25%);
    transition: all 0.1s ease-out;
  }
  &:hover .material-symbols-outlined,
  button[aria-expanded='true'] .material-symbols-outlined {
    color: ${color};
    transform: translateX(0);
    transition: all 0.1s ease-in;
  }
`;
